.comm-main{
    margin: 0 1%;
    padding: 1% 0;
    border-top: 1px solid var(--lightColor);
}
.comm-headings, .comm-row2{
    display: flex;
    justify-content: space-between;
}
.comm-headings {
    font-size: small;
    margin: 1% 0;
}
.comm-headings{
    text-align: center;
    font-weight: normal;
}
.comm-row1, .comm-row1-2{
    display: flex;
    justify-content: right;
}
.comm-r0 > button{
    margin: 0 1%;
    color: grey;
    background-color: white;
    cursor: pointer;
    width: 1em
}
.comm-r0 > button :hover{
    color: black;
}
.comm-row2{
    margin: 1% 0;
    height: 30px;
}
.comm-col1, .comm-col3, .comm-col4{
    width: 25%;
}

.comm-col2{
    width: 10%;
}
.comm-row2 input{
    background-color: white;
    height: 90%;
    width: 95%;
    border: 1px solid var(--lightColor);
    border-radius: 5px;
}

.comm-row2 input::placeholder {
    text-align: center;
    text-indent: 2%;
    font-weight: bold;
    font-size: x-small;
    font-style: italic;
}

.comm-output-container{
    display: flex;
    justify-content: right;
    border-top:2px solid var(--lightColor);
    border-bottom: 2px solid var(--lightColor);
}
.comm-output{
    margin: auto 0 auto 5%;
    color: var(--darkColor);
    padding-right: 1%;
    overflow: auto;
}
.comm-output-container > .ttText{
    font-size: small;
}
.comm-row2 .amount{
    text-align: right;
    padding-right: 5%;
}
.comm-row2 .rate{
    text-align: center;
    font-weight: bold;
    font-size: medium;
    color: var(--darkColor);
}
.comm-row3{
    font-weight: normal;
    margin: 2% 0;
    font-size: smaller;
    color: var(--darkColor);
}

.comm-row1-2 .comm-copy1{
    width: calc(70% - 1em);
    text-align: left;
}
.comm-row1-2 .comm-copy2{
    width: 72%;
    text-align: left;
}