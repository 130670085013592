#table-card{
    --lightColor: #addac1;
    --midColor: #7c9c8b;
    --darkColor: #45574d;
    position: relative;
    padding-bottom: 10px;
}
.local-currency{
    position: absolute;
    width: 25%;
    font-size: small;
    right: 2%;
}
.local-currency-heading{
    text-align: center;
    font-size: 0.9em;
    font-weight: normal;
}
.local-currency-dropdown{
    width: 75% !important;
    top: 102%;
    left: 12%;
}
.local-currency-search{
    border-radius: 3px;
    border: solid 1px var(--midColor);
    height: 20px;
    width: 98%;
    text-indent: 5px;
    text-align: start;
    font-style: italic;
}
.local-currency-search::placeholder{
    text-align: center;
    font-weight: bold;
}
.local-currency-button{
    background-color: var(--lightColor);
    width: 75%;
    margin: 0 auto ;
    border: 1px solid var(--lightColor);
    cursor: pointer;
    border-radius: 3px;
    box-shadow: 0px 1px 2px 1px var(--midColor);
    position: relative;
    height: 20px;
}

/*---Rates table---*/
.rates-table{
    display: block;
    margin: 0 auto ;
    border-collapse: collapse;
}
.rates-heading{
    display: flex;
    line-height: 1.5em;
    padding: 1%;

}
.rates-error-container{
    margin: 1%;
}
.error-r1{
    height: 5px;
}
.last-update{
    font-size: small;
    color: var(--darkColor);
    text-align: center;
    height: 1rem;
}
.rates-data{
    display: flex;
    height: 25px;
    padding: 1%;
}
.column1{
    position: relative;
    width: 25%;
}
.column2, .column3{
    width: 32.5%;
}
.column4{
    width: 10%;
}
thead{
    width: 100%;
    display: inline-table;
}
tbody{
    display: block;
    width: 100%;
}

.rates-table-caption{
    line-height: 1.8rem;
    font-weight: bold;
    width: 100%;
    margin: 0 auto;
    padding: 1.5% 0;
    background-color: var(--lightColor);
    text-align: center;
    border-radius: 5px 5px 0 0;
}
.rates-table th{
    font-size: 0.8rem;
    letter-spacing: 0.1rem;
    border-bottom:solid 1px #7c9c8b;
}
.rates-input{
    display: block;
    margin: 0 auto;
    text-align: end;
    font-size: small;
    width: 90%;
    height: 90%;
    border-radius: 5px;
    border: solid 1px var(--lightColor);
}
.rates-add{
    background-color: white;
    width: 50%;
    margin: 0 auto;
}
.rates-add:hover{
    background-color: rgb(240, 240, 240);
}
.reset-container{
    display: flex;
}
.rates-button-cont{
    display: flex;
    height: 100%;
}
.rates-button-cont button{
    width: 50%;
}
.rates-reset{
    padding: 0;   
}
/*---Rates table---*/

/*---dropdown---*/

.dropBtn{
    width: 100%;
    height: 100%;
    background-color: white;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0px 1px 2px 0px var(--midColor);
    border: solid 1px var(--lightColor) ;
    display: flex;
    justify-content: center;
}

.dropdownContent, .local-currency-dropdown{
    list-style: none;
    background-color: white;
    width: 100%;
    z-index: 1;
    max-height: 120px;
    cursor: pointer;
    overflow-y: auto;
    overflow-x: hidden;
    border-bottom: solid 2px var(--midColor);
    box-shadow: 0px 1px 2px 1px var(--midColor);
    position: absolute;
}

.dropdownContent.inactive .local-currency-dropdown.inactive{
    display: none;
}
.currency-list, .home-dropdown-list{
    font-size: small;
    color: var(--darkColor);
    display: flex;
    justify-content: center;
    margin: auto;
    padding: 2% 0;
    font-weight: bold;
}
.home-dropdown-list{
    padding: 4% 0;
}
.currency-list:hover, .home-dropdown-list:hover{
    background-color: var(--lightColor);
}

/*---dropdown---*/

@keyframes fade {
    0% {opacity: 0;}
    20% {opacity: 1}
    80% {opacity: 1}
    100% {opacity: 0;}
}
.error-fade{
    animation: fade 3s ease-in-out;
}

@media only screen and (min-width: 1024px){
    .local-currency{
        width: 15%;
    }
    
}