
.calc-headings{
    display: flex;
    margin: 0 ;
    border-radius: 5px 5px 0 0;
    border-top: 4px solid var(--lightColor);
    border-bottom: 2px solid var(--lightColor);
    text-align: center;
    font-weight: normal;
    font-size: small;
    justify-content: space-between;
}
.calc-main{
    margin: 0 1%;
    padding: 1% 0;
}

.calc-res{
    border-top: solid 2px var(--lightColor); 
    border-bottom: solid 2px var(--lightColor);
}
.res-item{
    width: 100%;
    margin: auto;
    text-align: right;
    overflow: auto;
}
.cols{
    display: flex;
}
.input-col{
    width: 20%;
    height: 1.5rem;
}
.res-col{
    width: 25%;
    height: 1.5rem;
}
.sign-col{
    width: 5%;
}
.sign-item{
    width: 100%;
    margin: auto;
    text-align: center;
    font-size: larger;
}
.input-col > input{
    width: 100%;
    border: 1px solid var(--lightColor);
    height: 100%;
    border-radius: 5px;
    text-align: right;
    padding-right: 5px;
}
.input-col > input::placeholder{
    text-align: center;
    font-size: x-small;
}
.calc-r0{
    display: flex;
    justify-content: right;
}
.calc-r0 > button {
    margin: 0 1%;
    color: gray;
    background-color: white;
    cursor: pointer;
}
.calc-r0 > button:hover{
    color: black;
}
.calc-r1{
    display: flex;
    height: 2rem;
    justify-content: space-between;
    margin: 1% 0;
}
.cols > .sub-col{
    margin: auto;
}