header{
    height: 5rem;
    background-color: var(--intermidColor);
    border-radius: 70px 70px 0 0;
    display: flex;
    justify-content: space-between;
}
.logo-container{
    height: 100%;
    display: flex;
    margin: auto 5%;
}
.nav-container{
    margin: auto 5%;
}
.nav-button{
    background-color: var(--intermidColor);
}
.nav-button:hover{
    cursor: pointer;
}
.nav-icon{
    position: relative;
    height: 1.5rem;
}
.nav-items{
    position: absolute;
    right: 5%;
    z-index: 2;
    width: 10rem;
    background-color: rgb(230, 230, 230);
    box-shadow: 0px 1px 2px 0px var(--intermidColor);
    border-radius: 5px;
    border-top: 3px solid var(--darkColor);
}
.nav-items li{
    list-style: none;
    text-align: left;
    line-height: 2rem;
}
.nav-items li:hover{
    background-color: var(--lightColor);
}

/*Big screen nav*/

.bg-nav-container{
    display: none;
}

/*Big screen nav*/

.links{
    display: inline-block;
    text-decoration: none;
    width: 100%;
    color: var(--darkColor);
    margin: 0 2%;
    font-weight: bold;
}
.headerIcon{
    margin: auto 1%;
    width: 7%;
    height: auto;
}
.headerText{
    margin: auto 1%;
    font-weight: bold;
    font-size: 1.5rem;
}
.active{
    color: black !important;
}

@media only screen and (min-width: 768px){
    .nav-items{
        width: 15rem;
    }
}

@media only screen and (min-width: 1024px){
    .nav-container{
        display: none;
    }
    .bg-nav-container{
        display: block;
        margin: auto 5%;
    }
    .bg-nav-items{
        display: flex;
        list-style: none;
        height: 100%;
    }
    .bg-nav-li{
        margin: 0 2%;
    }
    .bg-links:hover{
        color: black;
    }
    .bg-links{
        text-decoration: none;
        color: var(--darkColor);
        font-weight: bold;
    }
}