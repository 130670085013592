.footer-container{
    text-align: center;
    background-color: var(--intermidColor);
    padding:  1%;
    font-size: small;
    margin-top: auto;
}
.footer-r{
    margin: auto;
    line-height: 1.5rem;
}
.footer-tags{
    text-decoration: none;
    color: black;
    display: inline-block;
    margin: 0 1%;
   
}
.footer-tags:hover{
    text-decoration: underline;
}
.footer-tag-sep{
   font-size: large;
}