.about-main{
    margin: 10% 2%;
}
.about-head-2{
    text-align: center;
    font-weight: bold;
    font-size: x-large;
    margin: 2% 0;
    line-height: 2rem;
}
.about-p{
    line-height:  2rem;
    text-align: center;
    display: block;
}
@media only screen and (min-width: 1024px){
    .about-main{
        margin: 10% 20%;
    }
}

@media only screen and (min-width: 1440px){
    .about-main{
        margin: 10% 30%;
    }
}